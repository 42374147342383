<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-between">
        <div>
          <h2>{{ detailsData && detailsData.title }}</h2>
        </div>
        <div>
          <b-button
            variant="outline-primary"
            @click="$router.push({ name: 'manuel-utilisation-list' })"
          >
            <feather-icon
              icon="ArrowLeftCircleIcon"
              class="mr-25"
            />
            <span>Retour à la liste des manuels d'utilisations</span>
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            @click="$router.push({ name: 'manuel-utilisation-update', params: { id : detailsId } })"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-25"
            />
            <span>Modifier</span>
          </b-button>
          <b-button
            variant="danger"
            class="ml-1"
            @click="wouldDelete()"
          >
            <b-spinner
              v-if="isLoading"
              small
              class="mr-25"
            />
            <feather-icon
              v-else
              icon="TrashIcon"
              class="mr-25"
            />
            <span>Supprimer</span>
          </b-button>
        </div>
      </div>
    </b-card>
    <b-card>
      <div v-html="detailsData && detailsData.description" />
    </b-card>
    <b-modal
      id="modal-delete"
      ref="modal-delete"
      title="Suppression d'un manuel d'utilisation"
      cancel-variant="outline-secondary"
      ok-variant="danger"
      modal-class="modal-danger"
      ok-title="Oui"
      cancel-title="Non"
      no-close-on-backdrop
      @ok="deleteManuel"
    >
      <div>
        <b-card-text>
          <h5>Êtes-vous sûre de vouloir supprimer ce manuel d'utilisation ?</h5>
        </b-card-text>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BSpinner,
  BModal,
  BCardText,
  VBToggle,
  VBModal,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import localstorageService from '@/services/localstorage/localstorage.service'

// store modules and vuex utilities
// eslint-disable-next-line import/no-cycle
import manuelUtilisations from '@/store/manuel-utilisations'

// eslint-disable-next-line import/no-cycle
import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  components: {
    BCard,
    BButton,
    BSpinner,
    BModal,
    BCardText,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      isLoading: false,
      detailsId: null,
      detailsData: null,
    }
  },

  setup() {
    const requiredStoreModules = [
      { path: 'manuel-utilisations', module: manuelUtilisations },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)
    return {
      requiredStoreModules,
    }
  },

  watch: {
    $route: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      async handler(val, old) {
        this.detailsId = localstorageService.getManuelUtilisationId()
        await this.loadDetailsData()
      },
    },
  },

  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },

  methods: {
    ...mapActions('manuel-utilisations', {
      action_getManuelUtilisation: 'getManuelUtilisation',
      action_deleteManuelUtilisation: 'deleteManuelUtilisation',
    }),

    async loadDetailsData() {
      try {
        const response = await this.action_getManuelUtilisation(this.detailsId)
        if (response.status === 200) {
          this.detailsData = response.data.data
        } else {
          console.log("La réponse n'a pas un status HTTP 200")
        }
      } catch (error) {
        console.log('Erreur lors du chargement des détails du manuel d\'utilisation', error)
      }
    },

    wouldDelete() {
      this.$refs['modal-delete'].show()
    },

    async deleteManuel() {
      this.isLoading = true
      try {
        // eslint-disable-next-line no-unused-vars
        const response = await this.action_deleteManuelUtilisation(this.detailsId)
        if (response.status === 204) {
          this.isLoading = false
          this.$router.replace({ name: 'manuel-utilisation-list' })
          await this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Suppresion réussie',
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        } else {
          console.log("La réponse n'a pas un status HTTP 204")
        }
      } catch (error) {
        this.isLoading = false
        console.log("Erreur lors de la suppression du manuel d'utilisation", error)
        await this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Une erreur est survenue',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: '',
          },
        })
      }
    },
  },
}
</script>
